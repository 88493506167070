<template>
  <Card v-bind="{ ...metadata, ...$attrs }">
    <div class="qs-objects-container">
      <slot>
        <QsSingleObject
          v-for="qsObject in qsObjects"
          :key="qsObject.qlikId"
          v-bind="qsObject"
          style="flex-grow: 1; z-index: 10000"
        />
      </slot>
    </div>
  </Card>
</template>

<script>
import Card from "@/components/Card";
import QsSingleObject from "@/components/Qlik/QsSingleObject";
import { getQlikMetadata } from "@/config/qlikMetadata.js";

export default {
  components: { QsSingleObject, Card },
  props: {
    qlikIds: { type: Array, required: true, default: () => [""] },
    preservesOnDestroy: { type: Array, default: () => [] }
  },
  data: () => ({ overlayMenuOpen: false }),
  provide() {
    return { context: this.$data };
  },
  computed: {
    metadata() {
      return getQlikMetadata(this.qlikIds[0]);
    },
    qsObjects() {
      const { qlikIds, preservesOnDestroy } = this;

      return qlikIds.map(qlikId => ({
        qlikId,
        preserveOnDestroy: preservesOnDestroy.includes(qlikId)
      }));
    }
  }
};
</script>

<style scoped>
.qs-objects-container {
  display: flex;
  height: 100%;
}
</style>
